import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Markdown from "react-markdown"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentRenderer from "../components/ContentRenderer"
import Footer from "../components/Footer"
import GreenArrow from "../images/green-arrow.svg"
import IframeResizer from "iframe-resizer-react"

const Products = ({ data }) => {
  const page = data.allStrapiProductParent.edges[0].node

  const types = data.allStrapiProductTypes.edges

  console.log("Page data", page)

  return (
    <Layout>
      <Seo title="Products" />
      <section className="nextier-product-parent-hero">
        <div className="blue-line-container">
          <div className="container nextier-product-parent-hero__inner">
            <h1 className="nextier-product-parent-hero__title">{page.Title}</h1>
            {page.description && (
              <div className="nextier-product-parent-hero__text">
                <Markdown children={page.description} />
              </div>
            )}
            <img
              src={GreenArrow}
              className="nextier-product-parent-hero__arrow"
            />
          </div>
        </div>
        <img
          src={page?.Image?.url}
          className="nextier-product-parent-hero__image"
        />
      </section>
      <section className="product-parent-intro">
        <div className="blue-line-container">
          <div className="container product-parent-intro__inner">
            <div className="content-container">
              <h2 className="product-parent-intro__title">
                {page.productrangetitle}
              </h2>
              <div className="content-box">
                <div className="green-line"></div>
                <div className="product-parent-intro__text">
                  <Markdown children={page.productrangedescription} />

                  <Link
                    href={page.productrangebutton.Link}
                    className="primary-btn btn-arrow"
                  >
                    <span>{page.productrangebutton.Text}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {types.map(category => {
        if (category?.node?.products && category.node.products.length > 0)
          return (
            <section className="parent-products-block">
              <div className="blue-line-container">
                <div className="container parent-products-block__inner">
                  <div className="content-container">
                    <h2 className="parent-products-block__title">
                      {category.node.Title}
                    </h2>
                    <div className="parent-products-block__text">
                      <Markdown children={category.node.Description} />
                    </div>
                  </div>
                  <div className="parent-products-block__grid">
                    {category.node.products.map(product => (
                      <Link
                        to={`/products/${product.slug}`}
                        className="secondary-btn"
                      >
                        {product.Name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )
      })} */}

      <section className="parent-products-block">
        <div className="blue-line-container">
          <div className="container parent-products-block__inner">
            <div className="content-container">
              <h2 className="parent-products-block__title">Product Browser</h2>
              <div className="parent-products-block__text">
                {/* <Markdown children={category.node.Description} /> */}
              </div>
            </div>
            <div>
              <IframeResizer
                src="https://catalog.ulprospector.com/ProductBrowser.aspx?I=34417"
                style={{
                  width: "1px",
                  minWidth: "100%",
                }}
              />
            </div>
            <div className="parent-products-block__grid"></div>
          </div>
        </div>
      </section>

      {/* <section className="parent-products-block">
        <div className="blue-line-container">
          <div className="container parent-products-block__inner">
            <div className="content-container">
              <h2 className="parent-products-block__title">
                engineered thermoplastics
              </h2>
              <p className="parent-products-block__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed leo
                lorem ultrices fermentum nibh. Et egestas ornare faucibus.
              </p>
            </div>
            <div className="parent-products-block__grid">
              <a className="secondary-btn">
                {" "}
                PET (Polyethylene Terephthalate){" "}
              </a>
              <a className="secondary-btn"> PC (Polycarbonate) </a>
              <a className="secondary-btn">
                PC/ABS (Polycarbonate & crylonitrile-Butadiene-Styrene)
              </a>
              <a className="secondary-btn">
                {" "}
                PBT (Polybutylene Terephthalate){" "}
              </a>
              <a className="secondary-btn">
                {" "}
                ASA (Acrylonitrile Styrene Acrylate){" "}
              </a>
              <a className="secondary-btn"> PA66 (Nylon) </a>
              <a className="secondary-btn"> PA6 (Nylon) </a>
              <a className="secondary-btn">
                PC/PBT (Polycarbonate / Polybutylene Terephthalate)
              </a>
              <a className="secondary-btn">
                {" "}
                ABS (Acrylonitrile Butadiene Styrene){" "}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="parent-products-block">
        <div className="blue-line-container">
          <div className="container parent-products-block__inner">
            <div className="content-container">
              <h2 className="parent-products-block__title">
                commodity plastics
              </h2>
              <p className="parent-products-block__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed leo
                lorem ultrices fermentum nibh. Et egestas ornare faucibus.
              </p>
            </div>
            <div className="parent-products-block__grid pb-32">
              <a className="secondary-btn"> SAN (Styrene Acrylonitrile) </a>
              <a className="secondary-btn"> LDPE (Low-Density Polyethylene) </a>
              <a className="secondary-btn">
                {" "}
                HDPE (High-Density Polyethylene){" "}
              </a>
              <a className="secondary-btn"> PP (Polypropylene) </a>
              <a className="secondary-btn"> PS (Polystyrene) </a>
            </div>
          </div>
        </div>
      </section>

      <section className="full-width-grid parent-solutions">
        <img
          className="parent-solutions__image"
          src="/assets/images/pexels-oliver-sjöström-931018.jpg"
        />
        <div className="parent-solutions__content">
          <h2 className="parent-solutions__title">Custom Solutions</h2>
          <p className="parent-solutions__text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget purus
            in mollis malesuada turpis viverra pharetra, pellentesque urna.
            Aliquam eu netus auctor amet. Volutpat lorem et, amet amet congue.
            Aliquam pulvinar amet, congue maecenas dignissim pretium pharetra
            vestibulum.
          </p>
          <a className="primary-btn_alt btn-arrow">
            <span>View our Technical services</span>
          </a>
        </div>
      </section> */}
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiProductParent {
      edges {
        node {
          offsetImageBlock {
            Text
            Title
            buttonText
            id
          }
          productrangebutton {
            Text
            Link
            id
          }
          productrangedescription
          productrangetitle
          Title
          Image {
            alternativeText
            url
          }
        }
      }
    }
    allStrapiProductTypes {
      edges {
        node {
          Description
          Title
          products {
            Name
            slug
          }
        }
      }
    }
  }
`

export default Products
